const en = require('./en.json');
const ja = require('./ja.json');
const hi = require('./hi.json');
const te = require('./te.json');

export default {
  en,
  ja,
  hi,
  te,
};
