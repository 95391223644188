import Vue from 'vue';
import Amplify, * as AmplifyModules from 'aws-amplify';
import VModal from 'vue-js-modal';
import { AmplifyPlugin } from 'aws-amplify-vue';
import VueTour from 'vue-tour';
import 'vue-tour/dist/vue-tour.css';
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import VTooltip from 'v-tooltip';

import VueHtml2Canvas from 'vue-html2canvas';
import moment from 'moment';
import VueMatomo from 'vue-matomo';
import VueMasonry from 'vue-masonry-css';
import lineClamp from 'vue-line-clamp';
import App from './App.vue';
import { awsConfig } from './runtimeConfig';
import store from './store';
import router from './router/index';
import vuetify from './plugins/vuetify';
import i18n from './i18n';

Vue.use(VTooltip);
Vue.use(VueMaterial);
Vue.use(VueHtml2Canvas);
Vue.use(VueMaterial);
Amplify.configure(awsConfig);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(VueTour);
Vue.use(VueMasonry);
Vue.use(lineClamp);

Vue.use(VModal, {
  dialog: true,
  dynamicDefaults: {
    width: '90%',
    height: '80%',
  },
});

Vue.filter('formatDate', (inputDate, format) => {
  if (!inputDate && !format) return '';
  return moment(inputDate).format(`${format}`);
});

Vue.filter('secondsToMinutes', (duration) => {
  if (!duration) return '';
  return Math.round(duration / 60);
});

Vue.filter('secondsToHoursAndMinutes', (duration) => {
  if (!duration) return '';
  duration = Number(duration);
  const h = Math.floor(duration / 3600);
  const m = Math.floor((duration % 3600) / 60);

  const hDisplay = h > 0 ? `${h}h ` : '';
  const mDisplay = m > 0 ? `${m}m` : '';
  return hDisplay + mDisplay;
});

Vue.config.productionTip = false;

/* This will disable the vue devtools for the mentioned environments
For development purposes, update the array of envs below to allow devtools extensions */
const devtoolsDisabledEnvs = ['production'];
if (devtoolsDisabledEnvs.includes(process.env.NODE_ENV)) {
  Vue.config.devtools = false;
}

export const bus = new Vue();
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

const getMatomoConfig = () => {
  if (window.location.href.includes('localhost')) {
    return {
      siteId: 3,
      host: 'https://prez-piwigo-01.myprezent.com',
    };
  }
  const siteId = process.env.VUE_APP_MATOMO_SITE_ID;
  const host = process.env.VUE_APP_MATOMO_HOST;
  const appDomain = process.env.VUE_APP_DOMAIN;
  const envName = process.env.NODE_ENV;
  const subDomain = process.env.VUE_APP_SUB_DOMAIN;
  console.log(`matomo host: ${host} siteId: ${siteId}`);

  if (envName === 'production' && window.location.href.includes(subDomain)) {
    return { siteId, host };
  }
  if (window.location.href.includes(appDomain)) {
    return { siteId, host };
  }
  return {};
};
const { siteId, host } = getMatomoConfig();
if (siteId && host) {
  Vue.use(VueMatomo, {
    host,
    siteId,
    router,
    enableLinkTracking: true,
    trackPageView: true,
    enableHeartBeatTimer: true,
  });
}
